<template>
  <div class="show_meeting add_group">
    <div
      class="pages_header member-tablet-view d-flex align-items-center justify-content-between"
    >
      <h3 v-if="this.$route.params.id" class="page_title">تعديل مجموعه</h3>
      <h3 v-else class="page_title">إضافة مجموعه</h3>
      <div class="btns"></div>
    </div>

    <div class="form_container meeting-link">
      <div class="form_header text-center p-9">
        <div v-if="this.$route.params.id" class="title_add_group">
          تعديل مجموعة
        </div>
        <div v-else class="title_add_group">إضافة مجموعة</div>
      </div>
      <div v-if="form_avtive == 1">
        <div class="form_body">
          <div class="metting_info justify-content-center d-flex">
            <form class="row">
              <div
                v-if="error_mesage == 'neme_needed'"
                class="error_mesage col-md-12"
              >
                <p>برجاء ادخال اسم المجموعة</p>
              </div>
              <div
                v-if="error_mesage == 'Department_needed'"
                class="error_mesage col-md-12"
              >
                <p>برجاء ادخال إسم الإدارة التابعة</p>
              </div>
              <div class="col-md-12 form-group mb-0 text-center">
                <div class="photo text-center">
                  <div
                    class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${previewImage})` }"
                  >
                    <label
                      title="رفع صورة المستخدم"
                      class="image_uplode_input_label"
                    >
                      <i class="fas fa-camera"></i>
                      <input
                        class="image_uplode_input"
                        ref="fileInput"
                        type="file"
                        @input="pickFile"
                        size="60"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 d-block">
                <label for="group_name">اسم المجموعة</label>
                <input
                  v-model="form.name"
                  type="text"
                  placeholder="اسم المجموعة"
                  id="group_name"
                />
              </div>

              <div class="form-group col-md-6 d-block">
                <label for="group_department">الإدارة التابعة</label>
                <input
                  v-model="form.department"
                  type="group_department"
                  placeholder="الإدارة التابعة"
                  id="group_name"
                />
              </div>
            </form>
          </div>
          <!--  -->
        </div>
        <div
          v-if="this.$route.params.id"
          class="btns mt-5 d-flex justify-content-end"
        >
          <button
            class="btn_Green btn_main"
            @click="EditGroup()"
          >
            <span>تعديل المجموعة</span>
            <!-- <img src="/media/svg/long-arrow-left.svg" alt="long arrow left" /> -->
          </button>
        </div>
        <div v-else class="mt-5 d-flex justify-content-end">
          <button @click="CreateGroup()" class="btn_Gray btn_main">
            <span>حفظ وخروج</span>
        
          </button>

          <button class="btn_Green btn_main mr-2 ml-0" @click="form_avtive = 2">
            <span>إضافة الاشخاص</span>
          <i class="fas fa-arrow-left" style="margin-right: 20px;"></i>
          </button>
        </div>
      </div>
      <div v-else>
        <div class="search-people">
          <span class="title_addUser">البحث باسم الشخص</span>
          <v-select
            @input="AddUserInGroup"
            v-model="user_selected"
            :options="AllUsers"
            placeholder="البحث باسم الشخص"
            label="name"
            @search="onSearch"
          />
          <p class="gray-clr">
            إذا لم تجد الشخص الذي تبحث عنه يمكنك إضافته
            <a
              type="button"
              @click="
                popupAddUser = false;
                popupCreateUser = true;
              "
              class="here"
              >من هنا</a
            >
          </p>
          <div v-if="GroupUsers.length" class="users_to_add">
            <h3 v-for="(user, index) in GroupUsers" :key="index">
              {{ user.name }}
              <button @click="deleteUser(index)">
                <img style="width: 17px" src="/media/svg/delete1.svg" />
              </button>
            </h3>
          </div>
        </div>
        <div class="popoup_footer justify-content-end d-flex">
          <button class="btn_Green btn_main" @click="CreateGroup()">
            <span>حفظ المجموعه</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groups from "../../../http/requests/groups";
import vSelect from "vue-select";
import users from "../../../http/requests/users";
export default {
  name: "AddGroup",
  data() {
    return {
      form_avtive: 1,
      previewImage: null,
      user_photo: "",
      form: {},
      error_mesage: "",
      GroupUsers: [],
      GroupUsersIDS: [],
    };
  },
  components: { "v-select": vSelect },
  methods: {
    onSearch(search, loading) {
      loading(true);
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (search) {
          users.getusers(1, search).then((res) => {
            this.AllUsers = res.data.data;
            loading(false);
          });
        } else {
          loading(false);
          this.getAllUser();
        }
      }, 500);
    },
    getAllUser() {
      this.AllUsers = [];
      users
        .getusers()
        .then((res) => {
          this.AllUsers = res.data.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    AddUserInGroup(value) {
      if (this.GroupUsers.includes(value)) {
      } else {
        this.GroupUsers.push(value);
        this.GroupUsersIDS.push(value.id);
      }
    },
    deleteUser(index) {
      this.GroupUsers.splice(index, 1);
      this.GroupUsersIDS.splice(index, 1);
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      this.user_photo = input.files[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    CreateGroup(action) {
      if (!this.form.name) {
        this.error_mesage = "neme_needed";
      } else if (!this.form.department) {
        this.error_mesage = "Department_needed";
      } else {
        let formData = new FormData();
        formData.append("name", this.form.name);
        if (this.GroupUsers.length) {
          formData.append("users", JSON.stringify(this.GroupUsersIDS));
        }

        formData.append("department", this.form.department);
        formData.append("group_image", this.user_photo);
        this.$vs.loading();
        groups
          .Creategroup(formData)
          .then(() => {
            this.form = {};
            this.$vs.loading.close();
            if (action == "user") {
              this.form_avtive = 2;
            } else {
              this.$router.push("/groups");
              this.$vs.notify({
                text: "تم إضافة المجموعة بنجاح",
                color: "success",
              });
            }
          })
          .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      }
    },
    EditGroup() {
      if (!this.form.name) {
        this.error_mesage = "neme_needed";
      } else if (!this.form.department) {
        this.error_mesage = "Department_needed";
      }
      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("department", this.form.department);
      if (this.user_photo) {
        formData.append("group_image", this.user_photo);
      }
      formData.append("_method", "PUT");

      this.$vs.loading();
      groups
        .Updategroup(this.$route.params.id, formData)
        .then(() => {
          this.form = {};
          this.$vs.loading.close();
          this.$router.push("/groups");
          this.$vs.notify({
            text: "تم تعديل المجموعة بنجاح",
            color: "success",
          });
        })
          .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      
    },
    getSingleGroup(id) {
      this.form = {};
      this.$vs.loading();
      groups
        .getSinglegroup(id)
        .then((res) => {
          this.form = res.data.data;
          this.previewImage = res.data.data.image;
          this.$vs.loading.close();
        })
      .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      }
  },

  beforeRouteUpdate(to, from, next) {
    next();
    if (this.$route.params.id) {
      this.getSingleGroup(this.$route.params.id);
    }
  },
  created() {
    this.getAllUser();
    if (this.$route.params.id) {
      this.getSingleGroup(this.$route.params.id);
    }
  },
};
</script>
<style scoped lang="scss">
$thirdColor: #17a1bd;
.search-people {
  min-height: 300px !important;
  .gray-clr {
    font-size: 15px;
    margin-top: 14px;
    a {
      text-decoration: underline;
      color: $thirdColor;
    }
  }
}
.title_addUser {
  font-size: 18px;
}
.users_to_add {
  display: flex;
  h3 {
    height: 38px;

    background: #1eb04e;
    color: white;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    margin: 0;
    margin-left: 15px;
  }
}
.btn_Green{
   min-width: 150px;
}
.btn_Gray{
      min-width: 150px;
    text-align: center;
    justify-content: center;
}
</style>

